import validate from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/adria/FSBD/Projects-Hub/app/middleware/auth.global.ts";
import routes_45global from "/Users/adria/FSBD/Projects-Hub/app-plenamar/middleware/routes.global.ts";
import manifest_45route_45rule from "/Users/adria/FSBD/Projects-Hub/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  routes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}